import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_details_hero = _resolveComponent("details-hero")!
  const _component_chats_settings_empty_mode = _resolveComponent("chats-settings-empty-mode")!
  const _component_chat_channels_filter = _resolveComponent("chat-channels-filter")!
  const _component_chat_channels_actions = _resolveComponent("chat-channels-actions")!
  const _component_link_numbers_table = _resolveComponent("link-numbers-table")!
  const _component_chats_settings_page = _resolveComponent("chats-settings-page")!

  return (_openBlock(), _createBlock(_component_chats_settings_page, { breadcrumbs: _ctx.breadcrumbs }, {
    "page-content-white": _withCtx(() => [
      (_ctx.isEmptyMode)
        ? (_openBlock(), _createBlock(_component_chats_settings_empty_mode, { key: 0 }))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(_component_chat_channels_filter, {
              search: _ctx.search,
              "onUpdate:search": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
              "search-placeholder": "Search numbers"
            }, null, 8, ["search"]),
            _createVNode(_component_link_numbers_table, {
              headers: _ctx.tableHeaders,
              items: _ctx.tableItems,
              "show-selection": false,
              "items-name": "numbers",
              "show-link": "",
              link: { name: 'base.chats.settings.numberDetails' }
            }, {
              "body-cell-actions": _withCtx(() => [
                _createVNode(_component_chat_channels_actions)
              ]),
              _: 1
            }, 8, ["headers", "items", "link"])
          ], 64))
    ]),
    default: _withCtx(() => [
      _createVNode(_component_details_hero, {
        title: "SMS, MMS and voice calls",
        icon: "chat",
        class: "mb-4"
      })
    ]),
    _: 1
  }, 8, ["breadcrumbs"]))
}