
import { defineComponent, ref } from 'vue'
import ChatsSettingsPage from '@/components/pages/chats/settings/ChatsSettingsPage.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import ChatChannelsFilter from '@/components/pages/chats/settings/channels/ChatChannelsFilter.vue'
import LinkNumbersTable from '@/components/pages/chats/settings/channels/flow/linkNumbers/LinkNumbersTable.vue'
import type { TableHeaders } from '@/definitions/shared/types'
import { getTableData } from '@/services/tableService'
import ChatChannelsActions from '@/components/pages/chats/settings/channels/ChatChannelsActions.vue'
import ChatsSettingsEmptyMode from '@/components/pages/chats/settings/ChatsSettingsEmptyMode.vue'
import { useModes } from '@/compositions/modes'

export default defineComponent({
  components: {
    ChatChannelsActions,
    DetailsHero,
    ChatsSettingsPage,
    ChatChannelsFilter,
    LinkNumbersTable,
    ChatsSettingsEmptyMode,
  },
  setup() {
    const { isEmptyMode } = useModes()
    const breadcrumbs = [
      { label: 'Messenger', url: { name: 'base.chats' } },
      { label: 'Settings', url: { name: 'base.chats.settings' } },
      { label: 'SMS, MMS and voice calls' },
    ]
    const search = ref()

    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Number', value: 'number-slot' },
      { text: 'Label', value: 'label-slot' },
      { text: 'Voice', value: 'voice-slot', width: '80px' },
      { text: 'SMS', value: 'sms-slot', width: '80px' },
      { text: 'MMS', value: 'mms-slot', width: '160px' },
      { text: 'Provider', value: 'provider-slot' },
    ])

    const tableItems = ref(getTableData('numberChannels').map((e: any) => {
      return {
        ...e,
        provider: {
          ...e.provider,
          logo: require(`@/assets/images/byoc/short-logos/${e.provider.logo}`),
        },
      }
    }))

    return {
      isEmptyMode,
      tableItems,
      search,
      tableHeaders,
      breadcrumbs,
    }
  },
})
